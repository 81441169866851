<template>
  <div class="target">
    <vab-card
      class="target-card1"
      shadow="hover"
      skeleton
      :skeleton-rows="7"
      style="width: 48%; height: 305px"
    >
      <template #header>
        <vab-icon icon="user-3-line" />
        用户转化数据
      </template>
      <vab-chart
        class="target-echart1"
        :init-option="initOptions"
        :option="option"
        theme="vab-echarts-theme"
      />
    </vab-card>
    <vab-card
      class="target-card2"
      shadow="hover"
      skeleton
      :skeleton-rows="5"
      style="width: 50%; height: 305px"
    >
      <template #header>
        <span>
          <vab-icon icon="seedling-line" />
          实时访问数据
        </span>
      </template>
      <vab-chart
        class="target-echart2"
        :init-options="initOptions"
        :option="option2"
        theme="vab-echarts-theme"
      />
    </vab-card>
  </div>
</template>

<script>
  import { defineComponent, onMounted, reactive, watch, toRefs } from 'vue'
  import VabChart from '@/extra/VabChart'

  export default defineComponent({
    components: {
      VabChart,
    },
    props: {
      userConversion: {
        type: Array,
        default: [],
      },
      userPvData: {
        type: Object,
        default: {},
      },
    },
    emits: ['confirm'],
    setup(props, { emit }) {
      const colorList = ['#9E87FF', '#73DDFF', '#fe9a8b', '#F56948', '#9E87FF']
      watch(
        () => [props.userConversion, props.userPvData],
        ([newVal, pvData]) => {
          console.log(newVal, pvData, 'hvvv')
          state.option.series[0].data = newVal
          state.option2.series[0].data = handleData(pvData.sessionCnts)
          state.option2.series[1].data = handleData(pvData.visitUvNews)
          state.option2.series[2].data = handleData(pvData.toReals)
        }
      )

      const handleData = (arr) => {
        let valueData = arr.map((v) => {
          return v.data == '' ? (v.data = 0) : v.data
        })
        return valueData
      }
      const state = reactive({
        initOptions: {
          renderer: 'svg',
        },
        option: {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'z-index:1',
          },
          grid: {
            top: '0%',
            left: '2%',
            right: '4%',
            bottom: '0%',
            containLabel: true,
          },
          xAxis: [
            {
              splitLine: {
                show: false,
              },
              type: 'value',
              show: false,
            },
          ],
          yAxis: [
            {
              splitLine: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              type: 'category',
              axisTick: {
                show: false,
              },
              data: ['实名制注册总数', '二次授权总数', '一次授权总数'],
            },
          ],
          series: [
            {
              name: '总数',
              type: 'bar',
              barWidth: 25,
              label: {
                show: true,
                position: 'right',
                color: '#1890FF',
                fontSize: 14,
              },
              itemStyle: {
                borderRadius: [0, 5, 5, 0],
                color: new VabChart.graphic.LinearGradient(
                  0,
                  0,
                  1,
                  0,
                  ['#3ED572', '#399efd'].map((color, offset) => ({
                    color,
                    offset,
                  }))
                ),
              },
              data: [],
            },
          ],
        },
        option2: {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'z-index:1',
            // formatter: (params) => {
            //   console.log(params)
            // },
          },
          grid: {
            top: '4%',
            left: '2%',
            right: '2%',
            bottom: '0%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              data: [
                '00:00',
                '01:00',
                '02:00',
                '03:00',
                '04:00',
                '05:00',
                '06:00',
                '07:00',
                '08:00',
                '09:00',
                '10:00',
                '11:00',
                '12:00',
                '13:00',
                '14:00',
                '15:00',
                '16:00',
                '17:00',
                '18:00',
                '19:00',
                '20:00',
                '21:00',
                '22:00',
                '23:00',
              ],
              axisLine: {
                lineStyle: {
                  color: '#DCE2E8',
                },
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                interval: 3,
                color: '#556677',
                fontSize: 12,
                margin: 15,
              },
              axisPointer: {
                label: {
                  padding: [0, 0, 10, 0],
                  margin: 15,
                  fontSize: 12,
                  backgroundColor: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#fff',
                      },
                      {
                        offset: 0.86,
                        color: '#fff',
                      },
                      {
                        offset: 0.86,
                        color: '#33c0cd',
                      },
                      {
                        offset: 1,
                        color: '#33c0cd',
                      },
                    ],
                    global: false,
                  },
                },
              },
              boundaryGap: false,
            },
          ],
          yAxis: [
            {
              type: 'value',
              show: false,
            },
          ],
          series: [
            {
              name: '今日用户访问人数',
              type: 'line',
              data: [],
              symbolSize: 1,
              symbol: 'circle',
              smooth: true,
              yAxisIndex: 0,
              showSymbol: false,
              tooltip: {},
              lineStyle: {
                width: 5,
                color: new VabChart.graphic.LinearGradient(1, 1, 0, 0, [
                  {
                    offset: 0,
                    color: '#2cdd7d',
                  },
                  {
                    offset: 1,
                    color: '#189765',
                  },
                ]),
                shadowColor: 'rgba(115,221,255, 0.3)',
                shadowBlur: 10,
                shadowOffsetY: 20,
              },
              itemStyle: {
                color: colorList[1],
                borderColor: colorList[1],
              },
            },
            {
              name: '今日新用户访问人数',
              type: 'line',
              data: [],
              symbolSize: 1,
              symbol: 'circle',
              smooth: true,
              yAxisIndex: 0,
              showSymbol: false,
              tooltip: {},
              lineStyle: {
                width: 5,
                color: new VabChart.graphic.LinearGradient(1, 1, 0, 0, [
                  {
                    offset: 0,
                    color: '#E61A94',
                  },
                  {
                    offset: 1,
                    color: '#BB448C',
                  },
                ]),
                shadowColor: 'rgba(115,221,255, 0.3)',
                shadowBlur: 10,
                shadowOffsetY: 20,
              },
              itemStyle: {
                color: colorList[2],
                borderColor: colorList[2],
              },
            },
            {
              name: '今日完成实名制用户人数',
              type: 'line',
              data: [],
              symbolSize: 1,
              symbol: 'circle',
              smooth: true,
              yAxisIndex: 0,
              showSymbol: false,
              tooltip: {},
              lineStyle: {
                width: 5,
                color: new VabChart.graphic.LinearGradient(1, 1, 0, 0, [
                  {
                    offset: 0,
                    color: '#1ABDE6',
                  },
                  {
                    offset: 1,
                    color: '#3CA9C4',
                  },
                ]),
                shadowColor: 'rgba(115,221,255, 0.3)',
                shadowBlur: 10,
                shadowOffsetY: 20,
              },
              itemStyle: {
                color: colorList[3],
                borderColor: colorList[3],
              },
            },
          ],
        },
      })

      return {
        ...toRefs(state),
      }
    },
  })
</script>

<style lang="scss" scoped>
  .target {
    display: flex;
    justify-content: space-between;
    margin-bottom: $base-margin;
  }

  :deep() {
    .target-echart1 {
      width: 100%;
      height: 205px;
    }

    .target-echart2 {
      width: 100%;
      height: 205px;
    }
  }
</style>
